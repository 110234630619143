<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-container v-if="loading" style="width: 300px"></v-container>

    <v-container v-if="recordList.length">
      <v-row justify="center">
        <v-date-picker full-width v-model="date"
                       locale="ru-RU" first-day-of-week="1" color="teal lighten-1"
                       :events="functionEvents" event-color="red"
        ></v-date-picker>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <Wins
        :setWinsLoading="setWinsLoadingFromChildComponent"
        :date="date"
        :recordList="recordList"
        :winsLoading="winsLoading"
        :setTime="setTimeFromChildComponent"
        :loadRecordList="loadRecordList"
      />

      <v-divider class="mt-5 mb-1"></v-divider>

      <v-row justify="center">
        <v-card-text class="text-center">
          Вы записываете клиента:
          <b class="teal--text text--lighten-1">
            {{ $store.getters.lastName }} {{ $store.getters.firstName }}
          </b>
        </v-card-text>
      </v-row>

      <v-divider class="mt-1 mb-5"></v-divider>

      <v-row class="my-5" justify="center">
        <v-btn @click="recordHandler()"
               color="teal lighten-1"
               class="mx-1 white--text"
               small width="160" height="36"
               :disabled="!time"
        >
          Записать сюда
        </v-btn>

        <CreateWinsButton
          :dates="[date]"
          :loadRecordList="loadRecordList"
          :many="false"
          :setWinsLoading="setWinsLoadingFromChildComponent"
        />
      </v-row>

    </v-container>
  </v-card>
</template>

<script>
  import dateFilter from "../../filters/dateFilter"
  import Wins from "../../components/Wins"
  import CreateWinsButton from "../../components/CreateWinsButton"

  export default {
    name: "ChooseDateTimeMaster",
    components: {CreateWinsButton, Wins},
    data: () => ({
      date: "",
      recordList: [],
      loading: true,
      time: "",
      winsLoading: false,
    }),

    methods: {
      async loadRecordList() {
        const now = dateFilter(Date.now(), "ISO")
        this.winsLoading = true
        const json = await this.authFetch('/record/load-record-list-and-windows', {now})
        this.recordList = json.records
        this.recordList.sort(this.sortRecordListFunc)
        this.winsLoading = false
      },

      initDate() {
        this.date = this.$route.query.date || dateFilter(Date.now(), "ISO")
      },

      functionEvents(date) {
        if (dateFilter(date, "ISO") <= dateFilter(Date.now(), "ISO")) return false
        const events = []
        this.recordList.forEach(record => {
          if (record.date === date) {
            if (!record.open) events.push('#00f')
            else events.push('#b1fc92')
          }
        })
        if (events.length) return events
        return false
      },

      async deleteWindow(date, time) {
        await this.authFetch('/record/delete-window', {time, date})
      },

      async createWindowHandler() {
        if (!this.timeModal.length || !this.date.length) {
          this.$store.commit("setServerMessage", "Сначала выберите время")
          return
        }

        this.modal = false
        this.winsLoading = true
        const json = await this.authFetch(
          '/record/init-record',
          {date: this.date, time: this.timeModal}
        )
        if (json.status === 201) await this.loadRecordList()
        this.winsLoading = false
      },

      setWinsLoadingFromChildComponent(bool) {
        this.winsLoading = bool
      },

      setTimeFromChildComponent(t) {
        this.time = t
      },

      recordHandler() {
        this.$store.commit("setTime", this.time)
        this.$store.commit("setDate", this.date)
        this.$router.push("/choose-category-master")
      }
    },

    async mounted() {
      if (!this.$store.getters.firstName || !this.$store.getters.lastName || !this.$store.getters.phone) {
        await this.$router.push("/clients")
      }
      this.initDate()
      await this.loadRecordList()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>
